import React from 'react';
import { Link } from 'gatsby';

export default () => (
  <div
    style={{
      margin: 30,
      padding: 30,
      backgroundColor: '#f9f7f7',
      borderRadius: 15
    }}>
    <Link to='/'>Home</Link>
    <h2>Support</h2>
    <strong>
      <p>
        We will update this page with frequently asked questions very soon. For
        now, if you have any questions regarding the RaterFox IOS/Android app,
        web site or Chrome extension, please{' '}
        <a href='mailto:info@raterfox.com' target='_top'>
          send us an email
        </a>
        .
      </p>
    </strong>
  </div>
);
